import React from 'react';
import Navbar from '../components/Navbar';
import ExpandableButtonGrid from "../components/ExpandableButtonGrid";
import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

const RodzajeOrtezPage = () => {
    return (
        <>
            <Navbar />
            <ExpandableButtonGrid />
            <Footer />
        </>
    );
}

export default RodzajeOrtezPage;
