import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const TermsOfUse = () => {
    return (
        <div className="container my-5">
            <h2 className="mb-4">Warunki użytkowania</h2>
            <p><strong>1. Wprowadzenie</strong></p>
            <p>
                Niniejsze warunki użytkowania określają zasady korzystania ze Strony <strong>ortezy.com.pl</strong>. Korzystając z naszej Strony, użytkownik akceptuje poniższe warunki.
            </p>
            <p><strong>2. Korzystanie ze Strony</strong></p>
            <p>
                Użytkownik zobowiązuje się do korzystania ze Strony w sposób zgodny z prawem oraz w sposób, który nie narusza praw osób trzecich. Użytkownik nie może używać Strony w celu publikacji treści niezgodnych z prawem, obraźliwych lub w inny sposób szkodliwych.
            </p>
            <p><strong>3. Ograniczenie odpowiedzialności</strong></p>
            <p>
                Strona jest udostępniana w stanie „takim, w jakim jest”. Nie gwarantujemy, że Strona będzie działać bez przerw, błędów lub wirusów. Nie ponosimy odpowiedzialności za jakiekolwiek szkody wynikłe z korzystania ze Strony.
            </p>
            <p><strong>4. Własność intelektualna</strong></p>
            <p>
                Wszystkie treści zamieszczone na Stronie, w tym teksty, grafiki, logotypy oraz inne materiały są własnością naszej firmy lub jej licencjodawców i są chronione prawami własności intelektualnej.
            </p>
            <p><strong>5. Linki do stron trzecich</strong></p>
            <p>
                Strona może zawierać linki do stron internetowych innych firm. Nie ponosimy odpowiedzialności za treści zamieszczane na tych stronach ani za politykę prywatności tych stron.
            </p>
            <p><strong>6. Zmiany w warunkach użytkowania</strong></p>
            <p>
                Zastrzegamy sobie prawo do zmiany warunków użytkowania w dowolnym czasie. Zmiany te będą publikowane na naszej Stronie. Korzystanie z Strony po wprowadzeniu zmian oznacza akceptację nowych warunków.
            </p>
            <p><strong>7. Prawo właściwe</strong></p>
            <p>
                Niniejsze warunki użytkowania są regulowane prawem [Twojego kraju]. Wszelkie spory wynikłe z korzystania ze Strony będą rozstrzygane przez sądy właściwe dla siedziby naszej firmy.
            </p>
            <p><strong>8. Kontakt</strong></p>
            <p>
                W przypadku pytań dotyczących warunków użytkowania prosimy o kontakt pod adresem e-mail: eldmedaa22@ortezy.com.pl.
            </p>
        </div>
    );
}

export default TermsOfUse;
