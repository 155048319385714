import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const PrivacyPolicy = () => {
    return (
        <div className="container my-5">
            <h2 className="mb-4">Polityka prywatności</h2>
            <p><strong>1. Wprowadzenie</strong></p>
            <p>
                Niniejsza polityka prywatności określa zasady przetwarzania danych osobowych użytkowników naszej strony internetowej <strong>ortezy.com.pl</strong>. Jesteśmy zobowiązani do ochrony prywatności użytkowników oraz zapewnienia, że ich dane osobowe są traktowane z należytą starannością.
            </p>
            <p><strong>2. Jakie dane osobowe zbieramy</strong></p>
            <p>
                Gromadzimy dane osobowe użytkowników jedynie w przypadku, gdy użytkownik skorzysta z formularza kontaktowego dostępnego na naszej Stronie. W formularzu kontaktowym mogą być wymagane następujące dane:
                <ul>
                    <li>Imię</li>
                    <li>Nazwisko</li>
                    <li>Adres e-mail</li>
                    <li>Treść wiadomości</li>
                </ul>
            </p>
            <p><strong>3. Jak wykorzystujemy dane osobowe</strong></p>
            <p>
                Dane osobowe użytkowników są wykorzystywane wyłącznie w celu:
                <ul>
                    <li>Odpowiedzi na pytania i zapytania przesłane za pomocą formularza kontaktowego.</li>
                    <li>Poprawy jakości naszej Strony oraz usług.</li>
                </ul>
            </p>
            <p><strong>4. Bezpieczeństwo danych</strong></p>
            <p>
                Dokładamy wszelkich starań, aby zapewnić bezpieczeństwo danych osobowych naszych użytkowników. Stosujemy odpowiednie środki techniczne i organizacyjne w celu ochrony danych przed nieautoryzowanym dostępem, ujawnieniem, zmianą lub zniszczeniem.
            </p>
            <p><strong>5. Udostępnianie danych</strong></p>
            <p>
                Nie sprzedajemy ani nie wynajmujemy danych osobowych użytkowników stronom trzecim. Dane osobowe mogą być ujawniane jedynie w przypadku wymaganym przez prawo lub w związku z procedurami sądowymi.
            </p>
            <p><strong>6. Pliki cookie</strong></p>
            <p>
                Nasza Strona może używać plików cookie w celu poprawy jej funkcjonalności. Pliki cookie to małe pliki tekstowe przechowywane na urządzeniu użytkownika. Użytkownik ma możliwość zarządzania plikami cookie w ustawieniach swojej przeglądarki.
            </p>
            <p><strong>7. Kontakt</strong></p>
            <p>
                W przypadku pytań dotyczących polityki prywatności lub przetwarzania danych osobowych prosimy o kontakt pod adresem e-mail: eldmedaa22@ortezy.com.pl.
            </p>
            <p><strong>8. Zmiany w polityce prywatności</strong></p>
            <p>
                Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej polityce prywatności. O wszelkich zmianach będziemy informować na naszej Stronie.
            </p>
        </div>
    );
}

export default PrivacyPolicy;
