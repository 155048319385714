import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import FAQ from "../components/FAQ";

const FaqPage = () => {
    return (
        <>
            <Navbar />
            <br/>
            <br/>
            <FAQ />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Footer />
        </>
    );
}

export default FaqPage;
